<template>
<div class="balancecheck-container" id="js_balancecheck_container">
	<article class="balancecheck autonomic-nerves res">
		<h1 class="balancecheck__header">
			<img src="@/assets/img/balancecheck-autonomic-nerves/title01.png" alt="医師監修 自律神経のバランスチェック">
			<p><span>presented by</span><i class="balancecheck__header--icon sprite_autonomic-nerves logo" /></p>
		</h1>

		<!-- 結果 -->
		<section class="balancecheck__result" v-if="result === '2'">
			<h2 class="balancecheck__result--title">結果：乱れすぎ</h2>
			<h2 class="balancecheck__result--img"><i class="sprite_autonomic-nerves result2" /></h2>
			<p class="balancecheck__result--text">肩コリなどがあっても、すでに自覚症状がないようなら、かなり自律神経が乱れている可能性があります。日々の生活を見直しましょう。がんばりすぎも厳禁です。</p>
		</section>
		<section class="balancecheck__result" v-else-if="result === '1'">
			<h2 class="balancecheck__result--title">結果：不安定</h2>
			<h2 class="balancecheck__result--img"><i class="sprite_autonomic-nerves result1" /></h2>
			<p class="balancecheck__result--text">肩コリや首コリなどはありませんか？体が不調のサインを出し始めていたら、規則正しい生活を心がけ、心身ともにいたわってあげましょう。</p>
		</section>
		<section class="balancecheck__result" v-else-if="result === '0'">
			<h2 class="balancecheck__result--title">結果：良好</h2>
			<h2 class="balancecheck__result--img"><i class="sprite_autonomic-nerves result0" /></h2>
			<p class="balancecheck__result--text">現状では良好ですが、寝不足や不規則な生活など、ちょっとした自律神経の乱れはみられます。<br>ストレスをためないように注意して今の生活を維持していきましょう。</p>
		</section>
		<section class="balancecheck__result" v-else>
			<h2 class="balancecheck__result--title is-failed">結果の取得に失敗しました。</h2>
		</section>
		<!-- /結果 -->

		<!-- 監修者 -->
		<section class="balancecheck__supervisor">
			<h2 class="balancecheck__supervisor--img"><img src="@/assets/img/balancecheck-autonomic-nerves/img01.png" alt="面白いほどわかる 自律神経の新常識"></h2>
			<div class="balancecheck__supervisor--inner">
				<p class="balancecheck__supervisor--title"><span class="is-small">TJ MOOK</span><br>面白いほどわかる<br>自律神経の新常識</p>
				<p class="balancecheck__supervisor--text">著者：久手堅 司（監修）<br>出版社：宝島社</p>
			</div>
		</section>
		<!-- /監修者 -->

		<!-- アウトロ -->
		<section class="balancecheck__outro">
			<h2 class="balancecheck__outro--title">自律神経が乱れると、気圧や温度の変化で体調不良になる「気象病」の症状も出やすくなります。頭痛ーるは、不調の原因となる「気圧の変化」をお知らせします。</h2>
			<div class="growthhack-f__caution">
				<p class="growthhack-f__caution--right">低気圧で体調崩すかも。早めに用事を済ませよう！</p>
				<p class="growthhack-f__caution--left">痛みが出る前に薬を準備しておこう！</p>
			</div>
			<div class="growthhack-f__with-zutool">
				<p class="growthhack-f__with-zutool--title">
					with <span class="is-large">頭痛ーる</span>で<br>気圧と上手に付き合って<br>快適な毎日へ♪
				</p>
				<i class="growthhack-f__with-zutool--img sprite_autonomic-nerves with-zutool" />
			</div>
			<div class="about-rightnow__login">
				<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button" id="trackingBalancecheckAppDownloadBtn01_android">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
				<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button" id="trackingBalancecheckAppDownloadBtn01_ios">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
			</div>
			<div class="balancecheck__outro--box">
				<!-- <a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'lpautonomicnerves2021' } })" class="balancecheck__outro--back-balancecheck-button">自律神経の<br>バランスチェック特集にもどる</a> -->
				<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=balancecheckAutonomicNerves` } })" class="balancecheck__outro--back-balancecheck-button">自律神経の<br>バランスチェックにもどる</a>
				<a @click="$router.push({ name: 'Cpsite', query: { id: `${sugotokuContentsId}` } })" class="balancecheck__outro--back-top-button about-rightnow__login--button">TOPにもどる</a>
			</div>
		</section>
		<!-- /アウトロ -->
	</article>
</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'

// Vuex
import { mapGetters } from 'vuex'

export default {
	name: 'BalancecheckAutonomicNervesRes',
	components: {
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL
		}
	},
	props: {
		result: {
			type: String,
			default: ''
		},
		answer: {
			type: String,
			default: ''
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	mounted () {
		// コンテンツの高さをemitする処理
		// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
		// this.$nextTick(() => {
		// 	setTimeout(() => {
		// 		const domContainer = document.getElementById('js_balancecheck_container')
		// 		if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
		// 	}, 1000)
		// })
	},
	beforeDestroy () {
	},
	methods: {
		// map Vuex actions
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

// NOTE: このページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-5: 0.312rem;
$spacing-13: 0.812rem;
$spacing-14: 0.875rem;
$spacing-28: 1.75rem;
$spacing-30: 1.875rem;
$spacing-38: 2.375rem;
$spacing-40: 2.5rem;
$font-size-15: 0.937rem;
$font-size-19: 1.187rem;
$font-size-26: 1.625rem;
$balancecheck-primary: #FC7053;
$balancecheck-back-background: #34ABD1;
$balancecheck-back-border: #2091B5;
$line-height-13: 1.3;

.balancecheck {
	padding: 0;

	&__result {
		width: 92.5334%;
		margin: $spacing-20 auto 0;
		padding: 0 0 $spacing-30;
		font-size: $font-size-15;
		color: $text-primary;
		border-bottom: 1px solid $line-primary;

		&--title {
			margin: 0 auto;
			padding: $spacing-10;
			box-sizing: border-box;
			text-align: center;
			color: $balancecheck-primary;
			font-size: $font-size-26;
			line-height: $line-height-1;
			border-radius: 30px;
			border: 3px solid $balancecheck-primary;

			&.is-failed { font-size: $font-size-20; }
		}

		&--img {
			margin: $spacing-6 0 0 0;

			& > i { margin: 0 auto; }
		}

		&--text {
			font-family: $lp-family-primary;
			margin: 0;
			text-align: justify;
		}
	}

	&__supervisor { margin: $spacing-30 auto 0; }

	&__outro {
		margin: $spacing-13 0 0;

		&--title {
			margin: 0;
			padding: 0 $spacing-14;
			font-family: $lp-family-primary;
			font-size: $font-size-15;
			font-weight: normal;
		}

		.growthhack-f__caution { margin: $spacing-20 auto 0; }

		.about-rightnow__login { padding-top: 0; }

		&--box {
			width: 92.5334%;
			margin: $spacing-28 auto 0;
			border-top: 1px solid $line-primary;

			& > a {
				display: block;
				width: 86.4553%;
				margin: $spacing-40 auto 0;
			}
		}

		&--back-balancecheck-button {
			display: block;
			width: 80%;
			margin: $spacing-38 auto 0;
			padding: $spacing-10 0;
			box-sizing: border-box;
			background: $balancecheck-back-background;
			box-shadow: 0 3px $balancecheck-back-border;
			border-radius: 6px;
			text-align: center;
			color: $text-seconday;
			font-weight: bold;
			font-size: $font-size-19;
			line-height: $line-height-13;
			pointer-events: auto;

			@media (max-width: (374px)) { font-size: $font-size-16; }
			@media (max-width: (330px)) { font-size: $font-size-14; }
		}

		&--back-top-button { font-weight: normal; }
	}

	.sprite_autonomic-nerves {
		background-image: url(~@/assets/img/balancecheck-autonomic-nerves/sprite_res.png);
		background-size: (488px / 2) (1630px / 2);
		background-repeat: no-repeat;
		display: block;

		&.logo {
			width: (157px / 2);
			height: (40px / 2);
			background-position: (-5px / 2) (-5px / 2);
		}

		&.result0 {
			width: (478px / 2);
			height: (430px / 2);
			background-position: (-5px / 2) (-55px / 2);
		}

		&.result1 {
			width: (478px / 2);
			height: (430px / 2);
			background-position: (-5px / 2) (-495px / 2);
		}

		&.result2 {
			width: (460px / 2);
			height: (414px / 2);
			background-position: (-5px / 2) (-935px / 2);
		}

		&.with-zutool {
			width: (224px / 2);
			height: (266px / 2);
			background-position: (-5px / 2) (-1359px / 2);
		}
	}
}
</style>
